import React, { useEffect, useState } from "react";
import { Button, Row, Card } from "reactstrap";
import { Colxx, Separator } from "components/common/CustomBootstrap";

import AppLayout from "layout/AppLayout";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axiosClient";
import { useToken } from "auth/useToken";
import ListingTable from "components/ListingTable";
import moment from "moment";

const PricesList = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [count, setCount] = useState(1);
  const [token, setToken] = useToken();

  useEffect(() => {
    let isMounted = true;
    axios
      .get(`/prices?page=${currentPage}&per_page=10`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result) => {
        if (isMounted) {
          const { prices, count, page, pageCount } = result.data;
          setData(prices);
          setCount(count);
          setCurrentPage(page);
          setTotalPage(pageCount);
          setLoading(false);
        }
      });
    return () => {
      isMounted = false;
    };
  }, []);

  const navigate = useNavigate();

  const onSearchKey = (e) => {
    if (e.key === "Enter") {
      setLoading(true);
      handleSearch(e.target.value.toLowerCase());
    }
  };

  const handleSearch = (currentPage, selectedPageSize, word) => {
    ///
    axios
      .get(
        `/prices?search=${word}&page=${currentPage}&per_page=${selectedPageSize}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((result) => {
        const { prices, count, page, pageCount } = result.data;
        setData(prices);
        setCount(count);
        setCurrentPage(page);
        setTotalPage(pageCount);
        setLoading(false);
      });
  };

  const cols = React.useMemo(
    () => [
      {
        Header: "Date",
        accessor: "dateString",
        cellClass: "list-item-heading w-25",
        Cell: (props) => (
          <>
            <NavLink to={`${props.row.original._id}`}>{props.value}</NavLink>
          </>
        ),
      },
      {
        Header: "Company",
        accessor: "company",
        cellClass: "text-muted w-15",
        Cell: (props) => <>{props.value?.name}</>,
      },
      {
        Header: "Station",
        accessor: "station",
        cellClass: "text-muted w-15",
        Cell: (props) => <>{props.value?.name}</>,
      },
      {
        Header: "Fuel types",
        accessor: "fuelTypes",
        cellClass: "text-muted w-15",
        Cell: (props) => <>{props.value.map(({ name }) => name).join(", ")}</>,
      },
    ],
    []
  );

  return (
    <AppLayout>
      <div className="dashboard-wrapper">
        <Row>
          <Colxx xxs="12">
            <div className="mb-2">
              <h1>Prices List</h1>
              <div className="text-zero top-right-button-container">
                <Button
                  color="primary"
                  className="top-right-button"
                  onClick={() => navigate("add")}
                >
                  Add new
                </Button>
              </div>
            </div>
            <Separator className="mb-5" />
          </Colxx>
        </Row>
        <ListingTable
          columns={cols}
          data={data}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          handleSearch={handleSearch}
          totalPage={totalPage}
          totalItemCount={count}
          loading={loading}
          withNumeration={false}
        />
      </div>
    </AppLayout>
  );
};

export default PricesList;

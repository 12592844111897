import React, { useEffect, useState } from "react";
import axios from "axiosClient";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import {
  Row,
  Card,
  CardBody,
  Input,
  Button,
  Form,
  FormGroup,
  Label,
  Col,
} from "reactstrap";
import Resizer from "react-image-file-resizer";
import { Colxx, Separator } from "components/common/CustomBootstrap";

import AppLayout from "layout/AppLayout";
import { useToken } from "auth/useToken";
import Select from "react-select";
import { toast } from "react-toastify";
import { regionOptions } from "constants/selectValues";
import ConfirmModal from "components/ConfirmModal";

const initialValues = {
  name: "",
  tin: "",
  email: "",
  address: "",
  contactName: "",
  contactPhone: "",
  region: "Tashkent",
};

const AddCompany = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [values, setValues] = useState(initialValues);
  const [errorMessage, setErrorMessage] = useState("");
  const [preview, setPreview] = useState("");
  const [image, setImage] = useState({});
  const [isNew, setIsNew] = useState(
    location.pathname.split("/").slice(-1)[0] === "add"
  );
  const [loading, setLoading] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [token, setToken] = useToken();

  const { id } = useParams();

  const handleChange = (e) => {
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleRegionChange = (e) => {
    setValues({ ...values, region: e.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (values.password === "") {
      delete values.password;
    }
    let data = { ...values, logo: image };

    axios
      .post(`${isNew ? "/company/new" : `/company/${id}`}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((result) => {
        if (result.status === 200) {
          navigate("/app/companies");
        }
      })
      .catch((err) => {
        setErrorMessage(err.response.data);
      });
  };

  const handleDelete = () => {
    axios
      .delete(`/company/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result) => {
        if (result.status === 200) {
          navigate("/app/companies");
        }
      });
  };

  const handleImage = async (e) => {
    let file = e.target.files[0];
    let correctImageTypes = ["JPEG", "PNG", "WEBP"];
    let fileType = file.type.split("/")[1].toUpperCase();
    // console.log(fileType);

    if (!correctImageTypes.includes(fileType)) {
      toast.error("Only image files can be uploaded.");
    } else {
      setPreview(window.URL.createObjectURL(file));
      setLoading(true);
      //resize
      Resizer.imageFileResizer(
        file,
        720,
        500,
        fileType,
        100,
        0,
        async (uri) => {
          try {
            let { data } = await axios.post(
              "/upload-image",
              {
                image: uri,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                },
              }
            );
            //set image in te state
            setImage(data);
            setLoading(false);
          } catch (err) {
            console.log(err);
            setLoading(false);
          }
        }
      );
    }
  };

  const handleImageRemove = async () => {
    try {
      setValues({ ...values, loading: true });
      await axios.post(
        "/remove-image",
        { image },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setImage({});
      setPreview("");
      setValues({ ...values, loading: false });
    } catch (err) {
      console.log(err);
      setValues({ ...values, loading: false });
    }
  };

  useEffect(() => {
    !isNew && setLoading(true);
    !isNew &&
      axios
        .get(`/company/${id}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((result) => {
          let { data } = result;
          setValues(result.data);
          if (data.logo) {
            setImage(data.logo);
            setPreview(data.logo.Location);
          }
          setLoading(false);
        });
  }, [id, isNew]);

  return (
    <AppLayout>
      <div className="dashboard-wrapper">
        <Row>
          <Colxx xxs="12">
            <div>
              <h1>{isNew ? "New" : "Update"} Company</h1>
              <div className="pt-0 breadcrumb-container d-sm-block d-lg-inline-block float-r">
                {!isNew && (
                  <i
                    className="simple-icon-trash form-actions text-muted"
                    onClick={() => setDeleteModal(true)}
                  />
                )}
              </div>
            </div>
            <Separator className="mb-5" />
          </Colxx>
        </Row>
        {!loading && (
          <Row>
            <Colxx xxs="12" className="mb-4">
              <Card className="mb-4">
                <CardBody>
                  <Form>
                    <Row>
                      <Col md="2">
                        <FormGroup>
                          <Label>Logo</Label>
                          <Input
                            type="file"
                            name="image"
                            onChange={handleImage}
                            accept="image/*"
                            disabled={values.loading}
                          />
                        </FormGroup>
                        {preview && (
                          <>
                            <div class="position-absolute img-top-button">
                              <Button
                                outline
                                color="danger"
                                onClick={handleImageRemove}
                                className="icon-button btn btn-outline-red"
                              >
                                x
                              </Button>
                            </div>
                            <img
                              alt={"logo"}
                              src={preview}
                              className="card-img-top"
                            />
                          </>
                        )}
                      </Col>
                      <Col md="5">
                        <FormGroup>
                          <Label>Company Name</Label>
                          <Input
                            type="text"
                            name="name"
                            value={values.name}
                            onChange={handleChange}
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label>TIN</Label>
                          <Input
                            type="text"
                            name="tin"
                            value={values.tin}
                            onChange={handleChange}
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label>Address</Label>
                          <Input
                            type="text"
                            name="address"
                            value={values.address}
                            onChange={handleChange}
                          />
                        </FormGroup>
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <Label>City</Label>
                              <Input
                                type="text"
                                name="city"
                                value={values.city}
                                onChange={handleChange}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <Label>Region</Label>
                              <Select
                                value={{
                                  label: values.region,
                                  value: values.region,
                                }}
                                name="region"
                                className="react-select mb-3"
                                options={regionOptions}
                                onChange={handleRegionChange}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                      <Col md="5">
                        <FormGroup>
                          <Label>Contact Name</Label>
                          <Input
                            type="text"
                            name="contactName"
                            value={values.contactName}
                            onChange={handleChange}
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label>Phone</Label>
                          <Input
                            type="text"
                            name="contactPhone"
                            value={values.contactPhone}
                            onChange={handleChange}
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label>Email</Label>
                          <Input
                            type="email"
                            name="email"
                            value={values.email}
                            onChange={handleChange}
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label>Notes</Label>
                          <Input
                            type="text"
                            name="notes"
                            value={values.notes}
                            onChange={handleChange}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Colxx>
          </Row>
        )}
        <div className="centered">
          <Button
            color="light"
            className="top-right-button"
            onClick={() => navigate("/app/companies")}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            className="top-right-button ml-2"
            onClick={handleSubmit}
          >
            {isNew ? "Add" : "Update"}
          </Button>
        </div>
        &nbsp;
        <div>{errorMessage}</div>
        <ConfirmModal
          modal={deleteModal}
          setModal={setDeleteModal}
          action={handleDelete}
          title={`Are you sure you want to delete ${values.name}?`}
        />
      </div>
    </AppLayout>
  );
};

export default AddCompany;

import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Login from "./views/user/login";
import Error from "views/error";
import Unauthorized from "views/unauthorized";
import AppLayout from "layout/AppLayout";
import Blank from "views/app/Blank";
import UsersList from "views/app/users/usersList";
import AddUser from "views/app/users/add";
import { PrivateRoute } from "auth/privateRoute";
import CompaniesList from "views/app/companies/companiesList";
import AddCompany from "views/app/companies/addCompany";
import ClassificationsList from "views/app/fuels/classificationsList";
import ClassificationPage from "views/app/fuels/classificationPage";
import StationsList from "views/app/stations/stationsList";
import AddStation from "views/app/stations/addStation";
import FuelsList from "views/app/fuels/fuelsList";
import AddFuel from "views/app/fuels/addFuel";
import PricesList from "views/app/prices/pricesList";
import AddPrice from "views/app/prices/addPrice";
import FeedbacksList from "views/app/feedbacks/feedbacksList";
import EditFeedback from "views/app/feedbacks/editFeedback";
import Home from "views/support/home";
import Privacy from "views/support/privacy";

function App() {
  return (
    <div className="App">
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/login" element={<Login />} />

          <Route path="/error" element={<Error />} />
          <Route path="/unauthorized" element={<Unauthorized />} />
          <Route
            path="/app"
            element={
              <PrivateRoute>
                <AppLayout />
              </PrivateRoute>
            }
          />

          <Route
            path="/app/users"
            element={
              <PrivateRoute>
                <UsersList />
              </PrivateRoute>
            }
          />
          <Route
            path="/app/users/:id"
            element={
              <PrivateRoute>
                <AddUser />
              </PrivateRoute>
            }
          />

          <Route
            path="/app/companies"
            element={
              <PrivateRoute>
                <CompaniesList />
              </PrivateRoute>
            }
          />
          <Route
            path="/app/companies/:id"
            element={
              <PrivateRoute>
                <AddCompany />
              </PrivateRoute>
            }
          />

          <Route
            path="/app/stations"
            element={
              <PrivateRoute>
                <StationsList />
              </PrivateRoute>
            }
          />
          <Route
            path="/app/stations/:id"
            element={
              <PrivateRoute>
                <AddStation />
              </PrivateRoute>
            }
          />

          <Route
            path="/app/feedbacks"
            element={
              <PrivateRoute>
                <FeedbacksList />
              </PrivateRoute>
            }
          />
          <Route
            path="/app/feedbacks/:id"
            element={
              <PrivateRoute>
                <EditFeedback />
              </PrivateRoute>
            }
          />

          <Route
            path="/app/prices"
            element={
              <PrivateRoute>
                <PricesList />
              </PrivateRoute>
            }
          />
          <Route
            path="/app/prices/:id"
            element={
              <PrivateRoute>
                <AddPrice />
              </PrivateRoute>
            }
          />

          <Route
            path="/app/fuels"
            element={
              <PrivateRoute>
                <FuelsList />
              </PrivateRoute>
            }
          />
          <Route
            path="/app/fuels/:id"
            element={
              <PrivateRoute>
                <AddFuel />
              </PrivateRoute>
            }
          />

          <Route path="*" element={<Error />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;

/* eslint-disable react/no-array-index-key, react/no-danger */
import React, { useState, useEffect } from "react";
import { Col, Form, Label, Row } from "reactstrap";

const Home = (props) => {
  return (
    <div className="landing-page">
      <div className="main-container">
        <div className="content-container" id="home">
          <div className="section home">
            <div className="container">
              <div className="row home-row">
                <div className="col-12 col-xl-4 col-lg-5 col-md-6">
                  <div className="home-text">
                    <div className="display-1">
                      8092 <br />
                      Yoqilg'i shahobchalari
                    </div>
                    <p className="white mb-5">
                      Joylashuvingiz yaqinidagi yoqilg'i quyish shahobchalarini
                      oson toping hamda narxlarni taqqoslagan holda o'zingizga
                      qulay bahoda avtomobilingizni to'ldiring.
                      <br />
                      <br />
                      Ilova yaqin-atrofdagi shahobchalarni va ulardagi yoqilg'i
                      narxlarini xaritada va ro'yxat ko'rinishida taqdim etadi.
                      Kerakli shahobchaga bosgan holda u haqidagi to'la
                      ma'lumotni ko'rishingiz, lozim topsangiz fikringizni
                      qoldirishingiz ham mumkin.
                      <br />
                      <br />
                      Contact: info@monterra.com
                    </p>
                  </div>
                </div>
                <div className="col-12 col-xl-7 offset-xl-1 col-lg-7 col-md-6  d-none d-md-block">
                  <img alt="hero" src="/assets/img/landing-page/mockup.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;

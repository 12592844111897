import axios from "axios";
const setUrl = "https://api.logeld.com/gasoline/api";
// const setUrl = "http://localhost:6002/api";

const axiosClient = axios.create({
  baseURL: setUrl,
});

axiosClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 403) {
      localStorage.removeItem("token");
      window.location.href = "/login";
    }
    throw error;
  }
);

export default axiosClient;

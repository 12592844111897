import React, { useEffect, useState } from "react";
import axios from "axiosClient";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import {
  Row,
  Card,
  CardBody,
  Input,
  Button,
  Form,
  FormGroup,
  Label,
  Col,
} from "reactstrap";
import { Colxx, Separator } from "components/common/CustomBootstrap";

import AppLayout from "layout/AppLayout";
import { useToken } from "auth/useToken";
import Select from "react-select";
import { toast } from "react-toastify";
import ConfirmModal from "components/ConfirmModal";
import { statusOptions } from "constants/selectValues";

const initialValues = {
  name: "",
  classification: { name: "Select" },
  status: "Active",
};

const AddFuel = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [values, setValues] = useState(initialValues);
  const [errorMessage, setErrorMessage] = useState("");
  const [isNew, setIsNew] = useState(
    location.pathname.split("/").slice(-1)[0] === "add"
  );
  const [oldClassification, setOldClassification] = useState(null);
  const [classificationOptions, setClassificationOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [token, setToken] = useToken();

  const { id } = useParams();

  const handleChange = (e) => {
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });
  };
  const handleSelectChange = (e, name) => {
    if (name === "classification") {
      setValues({ ...values, classification: { _id: e.value, name: e.label } });
    } else {
      setValues({ ...values, [name]: e.value });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let data = {
      ...values,
      oldClassification,
      classification: values.classification?._id,
    };

    axios
      .post(`${isNew ? "/new-fuel-type" : `/fuel-type/${id}`}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((result) => {
        if (result.status === 200) {
          navigate("/app/fuels");
        }
      })
      .catch((err) => {
        setErrorMessage(err.response.data);
      });
  };

  const handleDelete = () => {
    axios
      .delete(`/fuel-type/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result) => {
        if (result.status === 200) {
          navigate("/app/fuels");
        }
      });
  };

  useEffect(() => {
    !isNew && setLoading(true);
    !isNew &&
      axios
        .get(`/fuel-type/${id}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((result) => {
          setValues(result.data);
          setOldClassification(result.data?.classification?._id);
          setLoading(false);
        });

    axios
      .get(`/classifications-all`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result) => {
        let { data } = result;
        let tempOptions = [];
        data.forEach((classification) =>
          tempOptions.push({
            label: classification.name,
            value: classification._id,
          })
        );
        setClassificationOptions(tempOptions);
      });
  }, [id, isNew]);

  return (
    <AppLayout>
      <div className="dashboard-wrapper">
        <Row>
          <Colxx xxs="12">
            <div>
              <h1>{isNew ? "New" : "Update"} Fuel Type</h1>
              <div className="pt-0 breadcrumb-container d-sm-block d-lg-inline-block float-r">
                {!isNew && (
                  <i
                    className="simple-icon-trash form-actions text-muted"
                    onClick={() => setDeleteModal(true)}
                  />
                )}
              </div>
            </div>
            <Separator className="mb-5" />
          </Colxx>
        </Row>
        {!loading && (
          <Row>
            <Colxx xxs="12" className="mb-4">
              <Card className="mb-4">
                <CardBody>
                  <Form>
                    <Row>
                      <Col md="6">
                        <FormGroup>
                          <Label>Fuel Type Name</Label>
                          <Input
                            type="text"
                            name="name"
                            value={values.name}
                            onChange={handleChange}
                          />
                        </FormGroup>                        
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <Label>Classification</Label>
                              <Select
                                value={{
                                  label: values.classification?.name,
                                  value: values.classification?._id,
                                }}
                                name="classification"
                                className="react-select mb-3"
                                options={classificationOptions}
                                onChange={(e) =>
                                  handleSelectChange(e, "classification")
                                }
                              />
                            </FormGroup>                            
                          </Col>
                        </Row>
                      </Col>
                      <Col md="6">                                                
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <Label>Sort Order</Label>
                              <Input
                                type="number"
                                name="order"
                                value={values.order}
                                onChange={handleChange}
                              />
                            </FormGroup>
                            <FormGroup>
                              <Label>Status</Label>
                              <Select
                                value={{
                                  label: values.status,
                                  value: values.status,
                                }}
                                name="status"
                                className="react-select mb-3"
                                options={statusOptions}
                                onChange={(e) =>
                                  handleSelectChange(e, "status")
                                }
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Colxx>
          </Row>
        )}
        <div className="centered">
          <Button
            color="light"
            className="top-right-button"
            onClick={() => navigate("/app/fuels")}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            className="top-right-button ml-2"
            onClick={handleSubmit}
          >
            {isNew ? "Add" : "Update"}
          </Button>
        </div>
        &nbsp;
        <div>{errorMessage}</div>
        <ConfirmModal
          modal={deleteModal}
          setModal={setDeleteModal}
          action={handleDelete}
          title={`Are you sure you want to delete ${values.name}?`}
        />
      </div>
    </AppLayout>
  );
};

export default AddFuel;

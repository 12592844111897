import React, { useEffect, useState } from "react";
import axios from "axiosClient";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import {
  Row,
  Card,
  CardBody,
  Input,
  Button,
  Form,
  FormGroup,
  Label,
  Col,
  CardTitle,
} from "reactstrap";
import Resizer from "react-image-file-resizer";
import { Colxx, Separator } from "components/common/CustomBootstrap";
import Switch from "rc-switch";
import "rc-switch/assets/index.css";

import AppLayout from "layout/AppLayout";
import { useToken } from "auth/useToken";
import Select from "react-select";
import { toast } from "react-toastify";
import { regionOptions, statusOptions } from "constants/selectValues";
import GlideComponent from "components/carousel/GlideComponent";
import ConfirmModal from "components/ConfirmModal";

const initialValues = {
  name: "",
  notes: "",
  address: "",
  contactName: "",
  latitude: "",
  longitude: "",
  region: "Tashkent",
  contactPhone: "",
  fuelTypes: [],
  status: "Active",
  company: { _id: null, name: "Select" },
  operator: { _id: null, name: "Select" },
};

const AddStation = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [values, setValues] = useState(initialValues);
  const [errorMessage, setErrorMessage] = useState("");
  const [preview, setPreview] = useState("");
  const [images, setImages] = useState([]);
  const [fuels, setFuels] = useState([]);
  const [companiesOptions, setCompaniesOptions] = useState([]);
  const [operatorsOptions, setOperatorsOptions] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);

  const [isNew, setIsNew] = useState(
    location.pathname.split("/").slice(-1)[0] === "add"
  );
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useToken();

  const { id } = useParams();

  const handleChange = (e) => {
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleSelectChange = (e, name) => {
    if (name === "company" || name === "operator") {
      setValues({ ...values, [name]: { _id: e.value, name: e.label } });
    } else {
      setValues({ ...values, [name]: e.value });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (values.password === "") {
      delete values.password;
    }
    let data = {
      ...values,
      images,
      company: values.company?._id,
      operator: values.operator?._id,
    };

    axios
      .post(`${isNew ? "/station/new" : `/station/${id}`}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((result) => {
        if (result.status === 200) {
          navigate("/app/stations");
        }
      })
      .catch((err) => {
        setErrorMessage(err.response.data);
      });
  };

  const handleDelete = () => {
    axios
      .delete(`/station/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result) => {
        if (result.status === 200) {
          navigate("/app/stations");
        }
      });
  };

  const handleImage = async (e) => {
    let file = e.target.files[0];
    let correctImageTypes = ["JPEG", "PNG", "WEBP"];
    let fileType = file.type.split("/")[1].toUpperCase();
    // console.log(fileType);

    if (!correctImageTypes.includes(fileType)) {
      toast.error("Only image files can be uploaded.");
    } else {
      setPreview(window.URL.createObjectURL(file));
      setLoading(true);
      //resize
      Resizer.imageFileResizer(
        file,
        720,
        500,
        fileType,
        100,
        0,
        async (uri) => {
          try {
            let { data } = await axios.post(
              "/upload-image",
              {
                image: uri,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                },
              }
            );
            //set image in te state
            setImages([...images, data]);
            setLoading(false);
          } catch (err) {
            console.log(err);
            setLoading(false);
          }
        }
      );
    }
  };

  const handleImageRemove = async (index) => {
    try {
      setValues({ ...values, loading: true });
      await axios.post(
        "/remove-image",
        { image: images[index] },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      let tempImages = [...images];
      tempImages.splice(index, 1);
      setImages(tempImages);
      setValues({ ...values, loading: false });
    } catch (err) {
      console.log(err);
      setValues({ ...values, loading: false });
    }
  };

  const handleTypeSwitch = (typeId, value) => {
    let tempFuels = values.fuelTypes;
    if (value) {
      tempFuels.push(typeId);
    } else {
      tempFuels = tempFuels.filter((one) => one !== typeId);
    }
    setValues({ ...values, fuelTypes: tempFuels });
  };

  useEffect(() => {
    setLoading(true);
    !isNew &&
      axios
        .get(`/station/${id}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((result) => {
          let { data } = result;
          setValues(data);
          if (data.images) {
            setImages(data.images);
          }
        });

    axios
      .get(`/classifications`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result) => {
        let { data } = result;
        setFuels(data.classifications);
      });

    axios
      .get(`/companies-all`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result) => {
        let { data } = result;
        let tempCompaniesOptions = [];
        data.forEach((company) =>
          tempCompaniesOptions.push({ label: company.name, value: company._id })
        );
        setCompaniesOptions(tempCompaniesOptions);
      });

    axios
      .get(`/users?filter=Operator`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result) => {
        let { users } = result.data;
        let tempOperatorsOptions = [];
        users.forEach((user) =>
          tempOperatorsOptions.push({ label: user.name, value: user._id })
        );
        setOperatorsOptions(tempOperatorsOptions);
        setLoading(false);
      });
  }, [id, isNew]);

  return (
    <AppLayout>
      <div className="dashboard-wrapper">
        <Row>
          <Colxx xxs="12">
            <div>
              <h1>{isNew ? "New" : "Update"} Station</h1>
              <div className="pt-0 breadcrumb-container d-sm-block d-lg-inline-block float-r">
                {!isNew && (
                  <i
                    className="simple-icon-trash form-actions text-muted"
                    onClick={() => setDeleteModal(true)}
                  />
                )}
              </div>
            </div>
            <Separator className="mb-5" />
          </Colxx>
        </Row>
        {!loading && (
          <>
            <Row>
              <Colxx xxs="12" className="mb-4">
                <Card className="mb-4">
                  <CardBody>
                    <Form>
                      <Row>
                        <Col md="2">
                          <FormGroup>
                            <Label>Images</Label>
                            <Input
                              type="file"
                              name="image"
                              onChange={handleImage}
                              accept="image/*"
                              disabled={values.loading}
                            />
                          </FormGroup>
                          {images.length !== 0 && (
                            <>
                              <GlideComponent
                                settings={{
                                  gap: 2,
                                  perView: 1,
                                  type: "carousel",
                                }}
                              >
                                {images.map((image, index) => {
                                  return (
                                    <div
                                      key={index}
                                      className="m-1"
                                      style={{ position: "relative" }}
                                    >
                                      <div class="position-absolute img-top-button">
                                        <Button
                                          outline
                                          color="danger"
                                          onClick={() =>
                                            handleImageRemove(index)
                                          }
                                          className="icon-button btn btn-outline-red"
                                        >
                                          x
                                        </Button>
                                      </div>
                                      <img
                                        alt={"logo"}
                                        src={image.Location}
                                        className="card-img-top"
                                      />
                                    </div>
                                  );
                                })}
                              </GlideComponent>
                            </>
                          )}
                        </Col>
                        <Col md="5">
                          <FormGroup>
                            <Label>Station Name</Label>
                            <Input
                              type="text"
                              name="name"
                              value={values.name}
                              onChange={handleChange}
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label>Address</Label>
                            <Input
                              type="text"
                              name="address"
                              value={values.address}
                              onChange={handleChange}
                            />
                          </FormGroup>
                          <Row>
                            <Col md="6">
                              <FormGroup>
                                <Label>City</Label>
                                <Input
                                  type="text"
                                  name="city"
                                  value={values.city}
                                  onChange={handleChange}
                                />
                              </FormGroup>
                            </Col>
                            <Col md="6">
                              <FormGroup>
                                <Label>Region</Label>
                                <Select
                                  value={{
                                    label: values.region,
                                    value: values.region,
                                  }}
                                  name="region"
                                  className="react-select mb-3"
                                  options={regionOptions}
                                  onChange={(e) =>
                                    handleSelectChange(e, "region")
                                  }
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col md="6">
                              <FormGroup>
                                <Label>Latitude</Label>
                                <Input
                                  type="text"
                                  name="latitude"
                                  value={values.latitude}
                                  onChange={handleChange}
                                />
                              </FormGroup>
                            </Col>
                            <Col md="6">
                              <FormGroup>
                                <Label>Longitude</Label>
                                <Input
                                  type="text"
                                  name="longitude"
                                  value={values.longitude}
                                  onChange={handleChange}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </Col>
                        <Col md="5">
                          <FormGroup>
                            <Label>Company</Label>
                            <Select
                              value={{
                                label: values.company?.name,
                                value: values.company?._id,
                              }}
                              name="company"
                              className="react-select mb-3"
                              options={companiesOptions}
                              onChange={(e) => handleSelectChange(e, "company")}
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label>Operator</Label>
                            <Select
                              value={{
                                label: values.operator?.name,
                                value: values.operator?._id,
                              }}
                              name="operator"
                              className="react-select mb-3"
                              options={operatorsOptions}
                              onChange={(e) =>
                                handleSelectChange(e, "operator")
                              }
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label>Status</Label>
                            <Select
                              value={{
                                label: values.status,
                                value: values.status,
                              }}
                              name="status"
                              className="react-select mb-3"
                              options={statusOptions}
                              onChange={(e) => handleSelectChange(e, "status")}
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label>Notes</Label>
                            <Input
                              type="text"
                              name="notes"
                              value={values.notes}
                              onChange={handleChange}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </Colxx>
            </Row>

            <Row>
              {fuels.map((fuel, index) => (
                <Colxx xxs="6" md="3" className="mb-4">
                  <Card className="h-100" key={index}>
                    <CardBody>
                      <CardTitle className="border-bottom">
                        <h2>{fuel.name}</h2>
                      </CardTitle>
                      {fuel.fuelTypes.map((type) => (
                        <div key={type._id} className="d-flex flex-row">
                          <span style={{ transform: "scale(0.8)" }}>
                            <Switch
                              className="mr-2 custom-switch custom-switch-primary-inverse"
                              checked={values.fuelTypes?.includes(type._id)}
                              onChange={(value) =>
                                handleTypeSwitch(type._id, value)
                              }
                            />
                          </span>
                          <h5>{type.name}</h5>
                        </div>
                      ))}
                    </CardBody>
                  </Card>
                </Colxx>
              ))}
            </Row>

            <Row>              
              <Colxx xxs="12" md="6" className="mb-4">
                <Card className="h-100">
                  <CardBody>
                    <CardTitle className="border-bottom">
                      <h2>Open hours</h2>
                    </CardTitle>                
                      <div className="table-responsive">
                        <table className="table table-borderless table-textmid">
                          <tbody>
                            <tr>
                              <th scope="row"><h5>Monday</h5></th>                              
                              <td><Input type="text" className="centered open-hours" /></td>
                              <td>-</td>
                              <td><Input type="text" className="centered open-hours" /></td>
                              <td>
                                <div className="custom-checkbox custom-control">
                                  <input type="checkbox" id="open24Checkbox" className="custom-control-input" />
                                  <label className="custom-control-label" for="open24Checkbox">24hrs</label>
                                </div>
                              </td>                              
                            </tr>
                            <tr>
                              <th scope="row"><h5>Tuesday</h5></th>
                              <td><Input type="text" className="centered open-hours" /></td>
                              <td>-</td>
                              <td><Input type="text" className="centered open-hours" /></td>                              
                              <td>
                                <div className="custom-checkbox custom-control">
                                  <input type="checkbox" id="open24Checkbox" className="custom-control-input" />
                                  <label className="custom-control-label" for="open24Checkbox">24hrs</label>
                                </div>
                              </td>                              
                            </tr>
                            <tr>
                              <th scope="row"><h5>Wednesday</h5></th>
                              <td><Input type="text" className="centered open-hours" /></td>
                              <td>-</td>
                              <td><Input type="text" className="centered open-hours" /></td>  
                              <td>
                                <div className="custom-checkbox custom-control">
                                  <input type="checkbox" id="open24Checkbox" className="custom-control-input" />
                                  <label className="custom-control-label" for="open24Checkbox">24hrs</label>
                                </div>
                              </td>                              
                            </tr>
                            <tr>
                              <th scope="row"><h5>Thursday</h5></th>
                              <td><Input type="text" className="centered open-hours" /></td>
                              <td>-</td>
                              <td><Input type="text" className="centered open-hours" /></td>  
                              <td>
                                <div className="custom-checkbox custom-control">
                                  <input type="checkbox" id="open24Checkbox" className="custom-control-input" />
                                  <label className="custom-control-label" for="open24Checkbox">24hrs</label>
                                </div>
                              </td>                              
                            </tr>
                            <tr>
                              <th scope="row"><h5>Friday</h5></th>
                              <td><Input type="text" className="centered open-hours" /></td>
                              <td>-</td>
                              <td><Input type="text" className="centered open-hours" /></td>  
                              <td>
                                <div className="custom-checkbox custom-control">
                                  <input type="checkbox" id="open24Checkbox" className="custom-control-input" />
                                  <label className="custom-control-label" for="open24Checkbox">24hrs</label>
                                </div>
                              </td>                              
                            </tr>
                            <tr>
                              <th scope="row"><h5>Saturday</h5></th>
                              <td><Input type="text" className="centered open-hours" /></td>
                              <td>-</td>
                              <td><Input type="text" className="centered open-hours" /></td>
                              <td>
                                <div className="custom-checkbox custom-control">
                                  <input type="checkbox" id="open24Checkbox" className="custom-control-input" />
                                  <label className="custom-control-label" for="open24Checkbox">24hrs</label>
                                </div>
                              </td>                              
                            </tr>
                            <tr>
                              <th scope="row"><h5>Sunday</h5></th>
                              <td><Input type="text" className="centered open-hours" /></td>
                              <td>-</td>
                              <td><Input type="text" className="centered open-hours" /></td>
                              <td>
                                <div className="custom-checkbox custom-control">
                                  <input type="checkbox" id="open24Checkbox" className="custom-control-input" />
                                  <label className="custom-control-label" for="open24Checkbox">24hrs</label>
                                </div>
                              </td>                              
                            </tr>
                          </tbody>
                        </table>
                      </div>                   
                  </CardBody>
                </Card>
              </Colxx>
              <Colxx xxs="12" md="6" className="mb-4">
                <Card className="h-100">
                  <CardBody>
                    <CardTitle className="border-bottom">
                      <h2>Offerings</h2>
                    </CardTitle>                    
                      <div className="d-flex flex-row">
                        <span style={{ transform: "scale(0.8)" }}>
                          <Switch
                            className="mr-2 custom-switch custom-switch-primary-inverse"
                          />
                        </span>
                        <h5>Car wash</h5>
                      </div>
                      <div className="d-flex flex-row">
                        <span style={{ transform: "scale(0.8)" }}>
                          <Switch
                            className="mr-2 custom-switch custom-switch-primary-inverse"
                          />
                        </span>
                        <h5>Spare parts</h5>
                      </div>
                      <div className="d-flex flex-row">
                        <span style={{ transform: "scale(0.8)" }}>
                          <Switch
                            className="mr-2 custom-switch custom-switch-primary-inverse"
                          />
                        </span>
                        <h5>Accessoires</h5>
                      </div>
                      <div className="d-flex flex-row">
                        <span style={{ transform: "scale(0.8)" }}>
                          <Switch
                            className="mr-2 custom-switch custom-switch-primary-inverse"
                          />
                        </span>
                        <h5>Restroom</h5>
                      </div>                    
                  </CardBody>
                </Card>
              </Colxx>              
            </Row>
          </>
        )}
        <div className="centered">
          <Button
            color="light"
            className="top-right-button"
            onClick={() => navigate("/app/stations")}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            className="top-right-button ml-2"
            onClick={handleSubmit}
          >
            {isNew ? "Add" : "Update"}
          </Button>
        </div>
        &nbsp;
        <div>{errorMessage}</div>
        <ConfirmModal
          modal={deleteModal}
          setModal={setDeleteModal}
          action={handleDelete}
          title={`Are you sure you want to delete ${values.name}?`}
        />
      </div>
    </AppLayout>
  );
};

export default AddStation;

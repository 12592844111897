/* eslint-disable react/no-array-index-key, react/no-danger */
import React from "react";

const Privacy = (props) => {
  return (
    <>
      <body>
        <h4>Privacy Policy</h4>
        Your privacy is important to us. It is 8092 App's policy to respect your
        privacy regarding any information we may collect from you through our
        app, 8092 App. We only ask for personal information when we truly need
        it to provide a service to you. We collect it by fair and lawful means,
        with your knowledge and consent. We also let you know why we’re
        collecting it and how it will be used. We only retain collected
        information for as long as necessary to provide you with your requested
        service. What data we store, we’ll protect within commercially
        acceptable means to prevent loss and theft, as well as unauthorized
        access, disclosure, copying, use or modification. We don’t share any
        personally identifying information publicly or with third-parties,
        except when required to by law. Our app may link to external sites that
        are not operated by us. Please be aware that we have no control over the
        content and practices of these sites, and cannot accept responsibility
        or liability for their respective privacy policies. You are free to
        refuse our request for your personal information, with the understanding
        that we may be unable to provide you with some of your desired services.
        Your continued use of our app will be regarded as acceptance of our
        practices around privacy and personal information. If you have any
        questions about how we handle user data and personal information, feel
        free to contact us. This policy is effective as of 6 August 2020.
        <h4>8092 App Terms of Service</h4>
        <h5>1. Terms</h5>
        By accessing our app, 8092 App, you are agreeing to be bound by these
        terms of service, all applicable laws and regulations, and agree that
        you are responsible for compliance with any applicable local laws. If
        you do not agree with any of these terms, you are prohibited from using
        or accessing 8092 App. The materials contained in 8092 App are protected
        by applicable copyright and trademark law.
        <h5>2. Use License</h5>
        Permission is granted to temporarily download one copy of 8092 App per
        device for personal, non-commercial transitory viewing only. This is the
        grant of a license, not a transfer of title, and under this license you
        may not: modify or copy the materials; use the materials for any
        commercial purpose, or for any public display (commercial or
        non-commercial); attempt to decompile or reverse engineer any software
        contained in 8092 App; remove any copyright or other proprietary
        notations from the materials; or transfer the materials to another
        person or "mirror" the materials on any other server. This license shall
        automatically terminate if you violate any of these restrictions and may
        be terminated by 8092 App at any time. Upon terminating your viewing of
        these materials or upon the termination of this license, you must
        destroy any downloaded materials in your possession whether in
        electronic or printed format.
        <h5>3. Disclaimer</h5>
        The materials within 8092 App are provided on an 'as is' basis. 8092 App
        makes no warranties, expressed or implied, and hereby disclaims and
        negates all other warranties including, without limitation, implied
        warranties or conditions of merchantability, fitness for a particular
        purpose, or non-infringement of intellectual property or other violation
        of rights. Further, 8092 App does not warrant or make any
        representations concerning the accuracy, likely results, or reliability
        of the use of the materials on its website or otherwise relating to such
        materials or on any sites linked to 8092 App.
        <h5>4. Limitations</h5>
        In no event shall 8092 App or its suppliers be liable for any damages
        (including, without limitation, damages for loss of data or profit, or
        due to business interruption) arising out of the use or inability to use
        8092 App, even if 8092 App or a 8092 App authorized representative has
        been notified orally or in writing of the possibility of such damage.
        Because some jurisdictions do not allow limitations on implied
        warranties, or limitations of liability for consequential or incidental
        damages, these limitations may not apply to you.
        <h5>5. Accuracy of materials</h5>
        The materials appearing in 8092 App could include technical,
        typographical, or photographic errors. 8092 App does not warrant that
        any of the materials on 8092 App are accurate, complete or current. 8092
        App may make changes to the materials contained in 8092 App at any time
        without notice. However 8092 App does not make any commitment to update
        the materials.
        <h5>6. Links</h5>
        8092 App has not reviewed all of the sites linked to its app and is not
        responsible for the contents of any such linked site. The inclusion of
        any link does not imply endorsement by 8092 App of the site. Use of any
        such linked website is at the user's own risk.
        <h5>7. Modifications</h5>
        8092 App may revise these terms of service for its app at any time
        without notice. By using 8092 App you are agreeing to be bound by the
        then current version of these terms of service.
        <h5>8. Governing Law</h5>
        These terms and conditions are governed by and construed in accordance
        with the laws of Uzbekistan and you irrevocably submit to the exclusive
        jurisdiction of the courts in that State or location.
      </body>
    </>
  );
};

export default Privacy;

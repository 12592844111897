import React, { useEffect, useState } from "react";
import { Button, Row, Card } from "reactstrap";
import { Colxx, Separator } from "components/common/CustomBootstrap";

import AppLayout from "layout/AppLayout";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axiosClient";
import { useToken } from "auth/useToken";
import ListingTable from "components/ListingTable";

const FeedbacksList = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [count, setCount] = useState(1);
  const [token, setToken] = useToken();

  useEffect(() => {
    let isMounted = true;
    axios
      .get(`/feedbacks?&page=${currentPage}&per_page=${10}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result) => {
        if (isMounted) {
          const { feedbacks, count, page, pageCount } = result.data;
          setData(feedbacks);
          setCount(count);
          setCurrentPage(page);
          setTotalPage(pageCount);
          setLoading(false);
        }
      });
    return () => {
      isMounted = false;
    };
  }, []);

  const navigate = useNavigate();

  const onSearchKey = (e) => {
    if (e.key === "Enter") {
      setLoading(true);
      handleSearch(e.target.value.toLowerCase());
    }
  };

  const handleSearch = (currentPage, selectedPageSize, word) => {
    ///
    axios
      .get(
        `/feedbacks?search=${word}&page=${currentPage}&per_page=${selectedPageSize}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((result) => {
        const { feedbacks, count, page, pageCount } = result.data;
        setData(feedbacks);
        setCount(count);
        setCurrentPage(page);
        setTotalPage(pageCount);
        setLoading(false);
      });
  };

  const cols = React.useMemo(
    () => [
      {
        Header: "#",
        // accessor: "title",
        cellClass: "list-item-heading",
      },
      {
        Header: "Driver",
        accessor: "reviewer",
        cellClass: "list-item-heading w-25",
        Cell: (props) => (
          <>
            <NavLink to={`${props.row.original._id}`}>
              {props.value?.username}
            </NavLink>
          </>
        ),
      },
      {
        Header: "Station",
        accessor: "station",
        cellClass: "text-muted w-15",
        Cell: (props) => <>{props.value?.name}</>,
      },
      {
        Header: "Feedback",
        accessor: "feedback",
        cellClass: "text-muted w-35",
        Cell: (props) => <>{props.value}</>,
      },
      {
        Header: "Rate",
        accessor: "rate",
        cellClass: "text-muted w-5",
        Cell: (props) => <>{props.value}</>,
      },
    ],
    []
  );

  return (
    <AppLayout>
      <div className="dashboard-wrapper">
        <Row>
          <Colxx xxs="12">
            <div className="mb-2">
              <h1>Feedbacks List</h1>
            </div>
            <Separator className="mb-5" />
          </Colxx>
        </Row>
        {!loading && (
          <ListingTable
            columns={cols}
            data={data}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            handleSearch={handleSearch}
            totalPage={totalPage}
            totalItemCount={count}
            loading={loading}
          />
        )}
      </div>
    </AppLayout>
  );
};

export default FeedbacksList;

import React, { useEffect, useState } from "react";
import axios from "axiosClient";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import {
  Row,
  Card,
  CardBody,
  Input,
  Button,
  Form,
  FormGroup,
  Label,
  Col,
  CardTitle,
} from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Colxx, Separator } from "components/common/CustomBootstrap";
import Switch from "rc-switch";
import "rc-switch/assets/index.css";

import AppLayout from "layout/AppLayout";
import { useToken } from "auth/useToken";
import Select from "react-select";
import { toast } from "react-toastify";
import { regionOptions, statusOptions } from "constants/selectValues";
import GlideComponent from "components/carousel/GlideComponent";
import ConfirmModal from "components/ConfirmModal";
import moment from "moment";

const initialValues = {
  date: new Date(),
  prices: [],
  company: { _id: null, name: "Select" },
  station: { _id: null, name: "Select" },
};

const AddPrice = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [values, setValues] = useState(initialValues);
  const [errorMessage, setErrorMessage] = useState("");
  const [fuels, setFuels] = useState([]);
  const [prices, setPrices] = useState([]);
  const [companiesOptions, setCompaniesOptions] = useState([]);
  const [stationsOptions, setStationsOptions] = useState([
    { value: null, label: "Select" },
  ]);
  const [deleteModal, setDeleteModal] = useState(false);

  const [isNew, setIsNew] = useState(
    location.pathname.split("/").slice(-1)[0] === "add"
  );
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useToken();

  const { id } = useParams();

  const handleChange = (e) => {
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleSelectChange = (e, name) => {
    if (name === "company" || name === "station") {
      if (name === "company") {
        axios
          .get(`/company-stations?companyId=${e.value}`, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((result) => {
            let { data } = result;
            let tempStationsOptions = [];
            data.forEach((station) =>
              tempStationsOptions.push({
                label: station.name,
                value: station._id,
              })
            );
            // setValues({ ...values, station: { _id: null, name: "Select" } });
            setStationsOptions(tempStationsOptions);
          });
      }

      setValues({ ...values, [name]: { _id: e.value, name: e.label } });
    } else {
      setValues({ ...values, [name]: e.value });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    let data = {
      ...values,
      prices,
      fuelTypes: Object.keys(prices),
      company: values.company?._id,
      station: values.station?._id,
      dateString: moment(values.date).format("DD/MM/YYYY"),
    };

    axios
      .post(
        `${
          isNew
            ? values.station._id === null
              ? "/company-price/new"
              : "/price/new"
            : `/price/${id}`
        }`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((result) => {
        setLoading(false);
        if (result.status === 200) {
          navigate("/app/prices");
        }
      })
      .catch((err) => {
        setLoading(false);
        setErrorMessage(err.response.data);
      });
  };

  const handleDelete = () => {
    axios
      .delete(`/price/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result) => {
        if (result.status === 200) {
          navigate("/app/prices");
        }
      });
  };

  const handleTypeValueChange = (typeId, e) => {
    setPrices({ ...prices, [typeId]: e.target.value });
  };

  useEffect(() => {
    setLoading(true);
    !isNew &&
      axios
        .get(`/price/${id}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((result) => {
          let { data } = result;
          setValues(data);
          const transformedObject = Object.keys(data.prices).reduce(
            (result, key) => {
              result[key] = data.prices[key].price;
              return result;
            },
            {}
          );
          setPrices(transformedObject);
        });

    axios
      .get(`/classifications`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result) => {
        let { data } = result;
        setFuels(data.classifications);
      });

    axios
      .get(`/companies-all`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result) => {
        let { data } = result;
        let tempCompaniesOptions = [];
        data.forEach((company) =>
          tempCompaniesOptions.push({ label: company.name, value: company._id })
        );
        setCompaniesOptions(tempCompaniesOptions);
        setLoading(false);
      });
  }, [id, isNew]);

  return (
    <AppLayout>
      <div className="dashboard-wrapper">
        <Row>
          <Colxx xxs="12">
            <div>
              <h1>{isNew ? "New" : "Update"} Price</h1>
              <div className="pt-0 breadcrumb-container d-sm-block d-lg-inline-block float-r">
                {!isNew && (
                  <i
                    className="simple-icon-trash form-actions text-muted"
                    onClick={() => setDeleteModal(true)}
                  />
                )}
              </div>
            </div>
            <Separator className="mb-5" />
          </Colxx>
        </Row>
        {!loading && (
          <>
            <Row>
              <Colxx xxs="12" className="mb-4">
                <Card className="mb-4">
                  <CardBody>
                    <Form>
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <Label>Date</Label>
                            <DatePicker
                              name="date"
                              selected={new Date(values.date)}
                              dateFormat="dd/MM/yyyy"
                              onChange={(date) =>
                                setValues({
                                  ...values,
                                  date: date.setHours(10),
                                })
                              }
                              placeholderText="dd/mm/yyyy"
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label>Company</Label>
                            <Select
                              value={{
                                label: values.company?.name,
                                value: values.company?._id,
                              }}
                              name="company"
                              className="react-select mb-3"
                              options={companiesOptions}
                              onChange={(e) => handleSelectChange(e, "company")}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <Label>Station</Label>
                            <Select
                              isDisabled={!values.company?._id}
                              value={{
                                label: values.station?.name,
                                value: values.station?._id,
                              }}
                              name="station"
                              className="react-select mb-3"
                              options={stationsOptions}
                              onChange={(e) => handleSelectChange(e, "station")}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </Colxx>
            </Row>

            <Row>
              {fuels.map((fuel, index) => (
                <Colxx xxs="6" md="3" className="mb-2">
                  <Card key={index}>
                    <CardBody>
                      <CardTitle className="border-bottom mb-2">
                        <h2>{fuel.name}</h2>
                      </CardTitle>
                      {fuel.fuelTypes.map((type) => (
                        <div key={type._id} className="d-flex flex-row mb-1">
                          <h5 className="my-auto">{type.name}</h5>
                          <Input
                            className="ml-2"
                            type="number"
                            value={prices[type._id] || null}
                            onChange={(e) => handleTypeValueChange(type._id, e)}
                          />
                        </div>
                      ))}
                    </CardBody>
                  </Card>
                </Colxx>
              ))}
            </Row>
          </>
        )}
        <div className="centered">
          <Button
            color="light"
            className="top-right-button"
            onClick={() => navigate("/app/prices")}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            className="top-right-button ml-2"
            onClick={handleSubmit}
            disabled={loading}
          >
            {isNew ? "Add" : "Update"}
          </Button>
        </div>
        &nbsp;
        <div>{errorMessage}</div>
        <ConfirmModal
          modal={deleteModal}
          setModal={setDeleteModal}
          action={handleDelete}
          title={`Are you sure you want to delete this price?`}
        />
      </div>
    </AppLayout>
  );
};

export default AddPrice;

/* eslint-disable react/no-array-index-key */
import React from "react";
import { Nav, NavItem } from "reactstrap";
import { NavLink, useLocation } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";

import menuItems from "constants/menu";
import { useUser } from "auth/useUser";

const Sidebar = () => {
  let user = useUser();
  const location = useLocation();
  const currentPath = location.pathname.split("/")[2];

  return (
    <div className="sidebar">
      <div className="main-menu">
        <div className="scroll">
          <PerfectScrollbar
            options={{ suppressScrollX: true, wheelPropagation: false }}
          >
            <Nav vertical className="list-unstyled">
              {menuItems &&
                menuItems.map((item) => {
                  return (
                    <NavItem
                      key={item.id}
                      className={
                        item.label.toLowerCase() === currentPath ? "active" : ""
                      }
                      hidden={!item.access.includes(user.role)}
                    >
                      <NavLink to={item.to} data-flag={item.id}>
                        <i className={item.icon} /> {item.label}
                      </NavLink>
                    </NavItem>
                  );
                })}
            </Nav>
          </PerfectScrollbar>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
